* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'BadaBoom BB';
  src: local('BadaBoom BB'), url(./fonts/BadaBoom.ttf) format('truetype');
}

html, body, #root {
  height: 100%;
  overflow-x: hidden;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-size: 2.4rem;
  font-family: 'BadaBoom BB', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  padding: 8px 16px;
  font-family: inherit;
  font-size: 3rem;
  border-radius: 12px;
  cursor: pointer;
  text-transform: uppercase;
}
